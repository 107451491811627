export async function resizeAndConvert (file, max_width = 2000, max_height = 2000, content_type = 'image/jpeg', file_ext = 'jpg') {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  const event = await new Promise(resolve => {
    reader.onload = event => resolve(event)
  })

  const img = new Image()
  img.src = event.target.result

  await new Promise(resolve => {
    img.onload = resolve
  })

  const canvas = document.createElement('canvas')
  let width = img.width
  let height = img.height

  if (width > height) {
    if (width > max_width) {
      height *= max_width / width
      width = max_width
    }
  } else {
    if (height > max_height) {
      width *= max_height / height
      height = max_height
    }
  }

  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, width, height)

  const blob = await new Promise(resolve => {
    canvas.toBlob(blob => resolve(blob), content_type, 1)
  })

  const newFile = new File([blob], `${file.name}.${file_ext}`, {
    type: content_type,
    lastModified: Date.now(),
  })

  return newFile
}

export function isImage(file) {
  const supportedImageTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/heic',
    'image/heic-sequence',
    'image/bmp',
    'image/x-ms-bmp',
  ]

  return supportedImageTypes.includes(file.type)
}

export async function handleCustomUpload(files, imageConfig, fileConfig) {
  let converted = [];
  let failed = [];
  let maxMb = 10;

  for (let i = 0; i < files.length; i ++) {
    let file = files[i]

    try {
      console.log(file.type, file.size)
      if (isImage(file)) {
        if (imageConfig && imageConfig.is_custom == true) {
          file = await resizeAndConvert(
            file,
            imageConfig.max_width,
            imageConfig.max_height,
            imageConfig.content_type,
            imageConfig.file_ext
          )
          maxMb = imageConfig.max_mb
        } else {
          maxMb = 10
        }

        if (file.size <= maxMb * 1024 * 1024) {
          converted.push(file)
        } else {
          failed.push({
            name: file.name,
            reason: `圖片超過 ${maxMb}MB`,
            actual_size: file.size,
            type: 'image'
          })
        }
      } else {
        if (fileConfig && fileConfig.is_custom == true) {
          maxMb = fileConfig.max_mb
        } else {
          maxMb = 10
        }

        if (file.size <= maxMb * 1024 * 1024) {
          converted.push(file)
        } else {
          failed.push({
            name: file.name,
            reason: `檔案超過 ${maxMb}MB`,
            actual_size: file.size,
            type: 'file'
          })
        }
      }
    } catch (error) {
      console.error(error)
      failed.push({
        name: file.name,
        reason: `轉檔失敗`,
        actual_size: file.size,
        type: 'image'
      })
    }
  }

  return { converted, failed }
}
