<template>
  <div>
    <div
      v-if="fetching"
      class="
        text-center
        d-flex
        justify-content-center
        align-items-center
        fetching
      "
    >
      <b-spinner variant="secondary"></b-spinner>
    </div>
    <div v-if="errorMessage" class="container error-message-box justify-content-center align-items-center">
      <div class="text-center">
        <h5>{{ errorMessage }}</h5>
        <p v-if="$store.state.liffChatroom.user.outer_code">錯誤代碼: {{ $store.state.liffChatroom.user.outer_code }}</p>
      </div>
    </div>
    <template v-else>
      <div v-if="chatroom" class="chatbox flex-fill" ref="chatbox">
        <header
          class="
            d-flex
            justify-content-between
            align-items-center
            sticky-top
            border-bottom
          "
        >
          <h1 class="ml-2">
            <template>
              <b-avatar
                class="mr-3"
                :src="chatroom.avatar_url"
              ></b-avatar
              >{{ chatroom.title }}
            </template>
          </h1>
        </header>

        <div
          class="
            chatbox-inner
            d-flex
            flex-column-reverse
            px-3
            position-relative
          "
        >
          <div class="upload-btn-container">
            <div v-if="!sending && !uploaded && !leaved" class="inner-upload">
              <span>請選擇你要上傳的檔案</span><br>
              <button class="btn upload-btn" @click="selectFile" :disabled="sending">
                <img src="@/assets/images/liff/chatbox/paperclip-2.png" width="25" height="25"/>
                選擇檔案
              </button>
            </div>
            <div class="inner-upload" v-if="sending && !leaved">
              <span>上傳 {{ filesLength }} 個檔案中...</span><br><br>
              <span v-if="filesLength === 1">如果您選擇多個檔案但系統只上傳一個檔案，</span><br>
              <span v-if="filesLength === 1">代表您的瀏覽器不支援多檔上傳，請分多次上傳。</span>
            </div>
            <div class="inner-upload" v-if="uploaded && !leaved">
              <span>上傳成功，請點下方回到聊天室</span><br>
              <button class="btn upload-btn" @click="back">
                回聊天室
              </button>
            </div>
            <div class="inner-upload" v-if="leaved">
              <span>本次上傳作業已結束，</span><br>
              <span>請點下方回到聊天室，或關閉本視窗。</span><br>
              <button class="btn upload-btn" @click="back">
                回聊天室
              </button>
            </div>
          </div>

        </div>

        <div class="typebox py-2 px-3 bg-white border-top">
          <input class="sr-only" type="file" ref="file" @change="uploadFile" :multiple="!isSamsung">
          <form @submit.prevent class="textbox">
            <!-- <button class="btn file" type="button" :disabled="sending" @click="selectFile">
              <img v-if="canMultiUpload" src="@/assets/images/liff/chatbox/paperclip-2.png" width="25" height="25" />
              <svg v-if="!canMultiUpload" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" witdh="25" height="25"><path d="M364.2 83.8C339.8 59.39 300.2 59.39 275.8 83.8L91.8 267.8C49.71 309.9 49.71 378.1 91.8 420.2C133.9 462.3 202.1 462.3 244.2 420.2L396.2 268.2C407.1 257.3 424.9 257.3 435.8 268.2C446.7 279.1 446.7 296.9 435.8 307.8L283.8 459.8C219.8 523.8 116.2 523.8 52.2 459.8C-11.75 395.8-11.75 292.2 52.2 228.2L236.2 44.2C282.5-2.08 357.5-2.08 403.8 44.2C450.1 90.48 450.1 165.5 403.8 211.8L227.8 387.8C199.2 416.4 152.8 416.4 124.2 387.8C95.59 359.2 95.59 312.8 124.2 284.2L268.2 140.2C279.1 129.3 296.9 129.3 307.8 140.2C318.7 151.1 318.7 168.9 307.8 179.8L163.8 323.8C157.1 330.5 157.1 341.5 163.8 348.2C170.5 354.9 181.5 354.9 188.2 348.2L364.2 172.2C388.6 147.8 388.6 108.2 364.2 83.8V83.8z" fill="#09101d" /></svg>
            </button> -->
            <div class="btn-container">
              <button v-if="!leaved" class="btn item" @click="selectFile" :disabled="sending">
                <img src="@/assets/images/liff/chatbox/paperclip-2.png" width="25" height="25" />
                選擇檔案
              </button>
              <button class="btn item" :disabled="sending" @click="back" style="height:33px;">
                回聊天室
              </button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import format from "date-fns/format";
import { v4 as uuidv4 } from "uuid";
// import withFetcher from "@/mixins/liff/withFetcher";
import { setTitle } from "@/store/modules/liff/helpers";
// import { getChatroomAvatar, getChatroomTitle  } from "@/utils/chatroomHelper.js";
// import chatoomMixin from '@/mixins/chatroom';
// import liff from "@line/liff";
import Client from '@/apis/liff/Chatroom'
import axios from 'axios'
import store from '@/store'
import Upload from 'gcs-browser-upload'
import { handleCustomUpload } from "@/utils/ImageHelper"

export default {
  data: () => ({
    fetching: false,
    errorMessage: null,
    loading: false,
    filesLength: 0,
    chatroom: {
      title: null,
      avatar_url: null,
      senders: [],
    },
    sending: false,
    uploaded: false,
    token: null,
    redirectBackUrl: null,
    jwtPayload: null,
    leaved: false,
    isSamsung: false,
    chatroomConfig: {},
  }),
  mounted () {
    // 因為在 Android 上，預設的 100vh 不等於 innerHeight，所以用 innerHeight 重新去計算 vh
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener("resize", function () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    this.fetch();
  },
  methods: {
    format,
    ...mapActions({
      upload: "liffChatroom/upload",
      getChatroom: "liffChatroom/getChatroom",
      markAsUploaded: "liffChatroom/markAsUploaded",
    }),
    async fetch() {
      this.fetching = true;
      this.token = this.$route.query.token;
      this.redirectBackUrl = this.$route.query.redirect_back_url;
      this.jwtPayload = this.parseJwt(this.token);

      console.log(this.jwtPayload);

      store.commit('liffChatroom/SET_TOKEN', this.jwtPayload.access_token);

      const orgCode = this.$route.params.orgCode
      const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/chatrooms`

      const liffInitializer = function () {};
      const tokenProvider = function () {
        return store.getters['liffChatroom/token'];
      }

      await Client.init({
        axios,
        baseURL,
        tokenProvider,
        liffInitializer
      });

      this.chatroom = await this.getChatroom({ chatroomId: this.jwtPayload.chatroom_id })

      this.isSamsung = navigator.userAgent.match(/SamsungBrowser/i);

      setTitle("多檔上傳");

      this.fetching = false;
    },

    back () {
      let url;
      if (this.isSamsung || !this.redirectBackUrl) {
        url = this.jwtPayload.line_oa_url;
      } else {
        url = this.redirectBackUrl;
      }

      var link = document.createElement('a');
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);

      link.click();
      this.leaved = true;
    },

    selectFile() {
      const fileInput = this.$refs.file

      fileInput.value = null
      fileInput.click()

      this.uploaded = false;
    },

    async uploadFile(e) {
      if (this.sending) {
        return
      }

      const files = [...e.target.files]

      if (!files.length) {
        return
      }

      this.sending = true

      let { converted, failed } = await handleCustomUpload(
        files,
        this.chatroom.module_config?.upload_image ?? {},
        this.chatroom.module_config?.upload_file ?? {}
      )

      if (failed.length > 0) {
        let message = '';

        for (let i = 0; i < failed.length; i++) {
          message += `檔案: ${failed[i].name} 上傳失敗，原因：${failed[i].reason}<br>`
        }

        this.$swal("失敗", message + `請重新選擇其他圖片。`, "warning");
        this.sending = false;
        return;
      }

      this.filesLength = converted.length;

      try {
        const state = uuidv4();

        const filesInfo = converted.map(this.getFileinfo)
        const { files: uploadInfo } = await this.upload({ chatroomId: this.chatroom.id, files: filesInfo })
        const requests = uploadInfo.map(({ fileId, uploadURL }, index) => {
          return this.createUploader({ id: fileId, url: uploadURL, file: converted[index] }).start()
        })

        await Promise.all(requests)
        await this.markAsUploaded({ chatroomId: this.chatroom.id, state, files: uploadInfo })
        this.uploaded = true;
      } catch (e) {
        this.$swal("上傳失敗", null, "error")
          .then(() => {
            this.back();
          });
      } finally {
        this.sending = false
      }
    },
    getFileinfo(file) {
      const name = file.name
      const mime = file.type
      const size = file.size

      return { name, mime, size }
    },
    createUploader({ id, url, file }) {
      return new Upload({
        id,
        url,
        file,
        chunkSize: 10485760, // chunk size must be a multiple of 262144 (0.25mb)
      })
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
  },
  watch: {
    loading: function () {
      this.$nextTick(function () {
        let chatbox = document.querySelector(".chatbox");
        if (chatbox.scrollHeight != this.scrollHeight) {
          let html = document.querySelector("html");
          html.classList.toggle("unsmooth");
          chatbox.scrollTop = chatbox.scrollHeight - this.scrollHeight;
          this.scrollHeight = chatbox.scrollHeight;
          html.classList.toggle("unsmooth");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chatroom {
  .b-avatar {
    width: 35px;
    height: 35px;
  }
  .chatbox {
    position: relative;
    overflow-y: auto;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;

    .chatbox-inner {
      min-height: calc(100% - 100px);
    }

    .upload-btn-container {
      display: flex;
      justify-content: center;
    }

    .inner-upload {
      position: absolute;
      top: 48%;
      text-align: center;

      span {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .upload-btn {
      margin-top: 10px;
      padding: 10px 50px !important;
      margin: 0px auto;
    }
  }

  .typebox {
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  header {
    background: white;
    color: #212529;
  }

  .textbox {
    position: relative;
    .btn.file, .btn.send {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .btn.file {
      left: 0px;
    }
    .message-box {
      height: calc(45px + 1.25em * (var(--lines) - 1));
      max-height: calc(45px + 1.25em * 9);
      line-height: 1.25;
      padding: calc((45px - 1.25em) / 2) 45px;
      overflow: hidden;
    }
    .btn.send {
      right: 0px;
    }
  }

  .fetching {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .error-message-box {
    height: 100vh;
  }

  small {
    font-size: 90%;
  }

  .download-all {
    color: #999;
    font-size: 0.85em;
  }

  .btn-container {
    display: flex;
    .item {
      flex: 1;
      & + .item {
        margin-left: 15px;
      }
    }
  }

  .btn {
    background-color: white;
    border: 0.5px solid #999;
    border-radius: 5px;
    padding: 3px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
</style>
